import DatabaseService from './DatabaseService';

const LocalStorageService = {

  createEmpsOption() {
    let values = []
    const keys = Object.keys(localStorage);
    let i = keys.length
    while (i--) {
      const item = JSON.parse(localStorage.getItem(keys[i]))
      values.push({
        "labelKey": item,
        "value": item.emp_id + " - " + item.full_name
      })
    }
    values.sort((a, b) => (
      a.labelKey.full_name > b.labelKey.full_name) ? 1 : ((b.labelKey.full_name > a.labelKey.full_name) ? -1 : 0)
    )
    return values;
  },

  fetchEmpsToCache() {
    localStorage.removeItem('employees');
    const keys = Object.keys(localStorage);
    if (keys.length === 0) {
      DatabaseService.fetchEmps().then(r => {
        r.map(doc => {
          localStorage.setItem(doc.id, JSON.stringify(doc.data))
          return {
            id: doc.id,
            data: doc.data
          };
        })
      })
    } else {
      let emps = []
      let i = keys.length
      while (i--) {
        const doc = JSON.parse(localStorage.getItem(keys[i]))
        emps.push({
          id: doc.emp_id,
          data: doc
        })
      }
      emps.sort((a, b) => (
        a.data.full_name > b.data.full_name) ? 1 : ((b.data.full_name > a.data.full_name) ? -1 : 0)
      )
      console.log('get emps from cache');
      return emps;
    }
  },

  clearCache() {
    localStorage.clear()
  }

};

export default LocalStorageService;
