import { addMonths } from 'date-fns';
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  updateDoc,
  where,
} from 'firebase/firestore';
import { COLL_NHAN_VIEN } from '../const';
import { db } from '../firebase';


const DatabaseService = {

  async fetchEmps() {
    console.log('get emps from api')
    const q = query(
      collection(db, COLL_NHAN_VIEN),
      orderBy('full_name', 'asc'));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => {
      return {
        id: doc.id,
        data: doc.data()
      };
    })
  },

  async fetchEmp(emp_id) {
    console.log('get emp from api')
    const docRef = doc(db, COLL_NHAN_VIEN, emp_id);
    const documentSnapshot = await getDoc(docRef);
    localStorage.setItem(emp_id, JSON.stringify(documentSnapshot.data()))
    return documentSnapshot.data();
  },

  async updateEmp(id, data) {
    updateDoc(doc(db, COLL_NHAN_VIEN, id), data).then(ref => {
      this.fetchEmp(id)
    });
  },

  async fetchDocs(coll_name) {
    console.log('get docs from api')
    const q = query(
      collection(db, coll_name),
      orderBy("created_at", "desc"));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => {
      return {
        id: doc.id,
        data: doc.data(),
        emp: JSON.parse(localStorage.getItem(doc.data().emp_id))
      }
    });
  },

  async fetchExpiryDocs(coll_name) {
    const start = new Date();
    const end = addMonths(start, 6)
    const q = query(
      collection(db, coll_name),
      where("training_expiry_date", ">=", start),
      where("training_expiry_date", "<=", end));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => {
      return {
        id: doc.id,
        data: doc.data()
      };
    });
  },

  async deleteDoc(coll_name, doc_id) {
    await deleteDoc(doc(db, coll_name, doc_id));
  },

  async addDoc(coll_name, data) {
    data.created_at = new Date()
    await addDoc(collection(db, coll_name), data)
  },

  async updateDoc(coll_name, id, data) {
    data.updated_at = new Date()
    await updateDoc(doc(db, coll_name, id), data)
  },

};

export default DatabaseService;
