import React from 'react';
import { Modal } from 'react-bootstrap';

const ModalSaveHeader = (props) => {
  return (
    <Modal.Header>
      <Modal.Title>{props.edit_mode ? 'Sửa' : 'Thêm'}: {props.title}</Modal.Title>
      <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={props.close}>
        <span aria-hidden="true">&times;</span>
      </button>
    </Modal.Header>
  )
}

export default React.memo(ModalSaveHeader)
