import React, { Suspense } from 'react';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import { Route, Routes } from 'react-router-dom';
import routes from '../routes';


const loading = (
  <div className="spinner-border" role="status">
    <span className="sr-only">Loading...</span>
  </div>
)

export const AppContent = () => {
  return (
    <Suspense fallback={loading}>
      <Routes>
        {routes.map((route, idx) => {
          return (
            route.element && (
              <Route
                key={idx}
                path={route.path}
                exact={route.exact}
                name={route.name}
                element={
                  <route.element
                    title={route.name}
                    coll_name={route.coll_name}
                    modal={route.modal}
                    columns={route.columns}
                  />
                }
              />
            )
          )
        })}
      </Routes>
    </Suspense>
  )
}

export default React.memo(AppContent)
