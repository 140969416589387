import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import BootstrapSelect from 'react-bootstrap-select-dropdown';
import { SELECT_EMP_DEFAULT_OPTION } from '../../const';
import LocalStorageService from '../../services/LocalStorageService';

const FormSelectEmp = (props) => {
  const [selected, setSelected] = useState()
  const [options, setOptions] = useState(SELECT_EMP_DEFAULT_OPTION)

  useEffect(() => {
    setOptions(LocalStorageService.createEmpsOption())
  }, []);

  const handleChange = (selectedOptions) => {
    setSelected(selectedOptions.selectedKey[0])
  }

  return (
    <>
      <Row className="mb-3">
        <Form.Group as={Col} controlId="nhan_vien">
          <Form.Label>Nhân viên</Form.Label>
          {props.edit_mode
            ? <Form.Control name="nhan_vien" defaultValue={props.row.emp.emp_id + ' - ' + props.row.emp.full_name} disabled required/>
            : <BootstrapSelect
              options={options}
              onChange={handleChange}
              showSearch={true}
              menuSize={5}
              className={"w-100"}
              placeholder={"Vui lòng chọn"}/>}
        </Form.Group>
      </Row>
      <Form.Control name="id" defaultValue={selected && selected.emp_id} disabled required hidden/>
    </>
  )
}

export default React.memo(FormSelectEmp)
