import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import React, { useState } from 'react';
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import LocalStorageService from '../../services/LocalStorageService';

const Login = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const handleAction = () => {
    setLoading(true)
    const authentication = getAuth();
    signInWithEmailAndPassword(authentication, email, password)
      .then((response) => {
        LocalStorageService.fetchEmpsToCache()
        navigate('/dashboard')
      })
      .catch((error) => {
        setLoading(false)
        if(error.code === 'auth/wrong-password'){
          alert('Sai mật khẩu!')
        }
        if(error.code === 'auth/user-not-found'){
          alert('Sai email!')
        }
      })
  }

  return (
    <Container fluid>
      <Row className="justify-content-center">
        <Col lg={3}>
          <Card className="mt-5">
            <Card.Body>
              <Form>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control type="email" placeholder="Enter email" onChange={e => setEmail(e.target.value)}/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control type="password" placeholder="Password" onChange={e => setPassword(e.target.value)}/>
                </Form.Group>
                {!loading
                  ?
                  <Button variant="primary" onClick={handleAction}>Login</Button>
                  :
                  <Button variant="primary" disabled>
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    {' '}Processing...
                  </Button>
                }
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default Login
