import React from 'react';
import { selectFilter } from 'react-bootstrap-table2-filter';
import CellAction from './components/commons/CellAction';
import ModalSaveDocument from './components/modals/ModalSaveDocument';
import ModalSaveDocumentANHK from './components/modals/ModalSaveDocumentANHK';
import ModalSaveDocumentGPND from './components/modals/ModalSaveDocumentGPND';
import ModalSaveDocumentHHNH from './components/modals/ModalSaveDocumentHHNH';
import ModalSaveDocumentLXVHTTB from './components/modals/ModalSaveDocumentLXVHTTB';
import ModalSaveDocumentTTBNG from './components/modals/ModalSaveDocumentTTBNG';
import {
  COLL_AN_NINH_HANG_KHONG,
  COLL_AN_TOAN_BUC_XA,
  COLL_AN_TOAN_SAN_DO,
  COLL_AN_TOAN_VE_SINH_LAO_DONG,
  COLL_BOI_DUONG_KY_NANG_MEM,
  COLL_DAO_TAO_KHAC,
  COLL_GIAY_PHEP_NANG_DINH,
  COLL_HANG_HOA_NGUY_HIEM,
  COLL_HANG_KHONG_DAN_DUNG,
  COLL_LAI_XE_VAN_HANH_TRANG_THIET_BI,
  COLL_QL_AN_NINH_HANG_KHONG,
  COLL_QUAN_LY_AN_TOAN,
  COLL_TRANG_THIET_BI_NHA_GA,
  COLL_VU_KHI_CONG_CU_HO_TRO,
} from './const';
import { formatDate, formatExpiredDate } from './utils/TableUtil';

const Dashboard = React.lazy(() => import('./containers/Dashboard'))
const Setting = React.lazy(() => import('./containers/Setting'))
const HoSoDaoTao = React.lazy(() => import('./containers/HoSoDaoTao'))
const Document = React.lazy(() => import('./containers/Document'))

const columnsEmp = [{
    dataField: 'emp.emp_id',
    text: 'Mã NV',
  }, {
    dataField: 'emp.full_name',
    text: 'Họ tên'
  }, {
    dataField: 'emp.birth',
    text: 'Ngày sinh'
  }, {
    dataField: 'emp.birth_place',
    text: 'Nơi sinh'
  }, {
    dataField: 'emp.title',
    text: 'Chức danh'
  }, {
    dataField: 'emp.identity_number',
    text: 'Số CCCD'
  }]

const routes = [
  {
    path: '/',
    name: 'Home'
  },
  {
    path: '/dashboard',
    name: 'Trang chủ',
    element: Dashboard
  },
  {
    path: '/setting',
    name: 'Cấu hình',
    element: Setting
  },
  {
    path: '/ho-so-dao-tao',
    name: 'Hồ sơ đào tạo',
    element: HoSoDaoTao
  },
  {
    path: '/hang-hoa-nguy-hiem',
    name: 'Hàng hoá nguy hiểm',
    coll_name: COLL_HANG_HOA_NGUY_HIEM,
    element: Document,
    modal: {
      save: ModalSaveDocumentHHNH
    },
    columns: [...columnsEmp, ...[
      {
        dataField: 'data.category',
        text: 'Chương trình',
        
        filter: selectFilter({
          options: {
            'CAT 6': 'CAT 6',
            'CAT 7': 'CAT 7',
            'CAT 8': 'CAT 8',
            'CAT 9': 'CAT 9',
            'CAT 10': 'CAT 10',
            'CAT 11': 'CAT 11',
            'CAT 12': 'CAT 12',
          },
          placeholder: "Tất cả"
        })
      }, {
        dataField: 'data.training_place',
        text: 'Nơi đào tạo',
      }, {
        dataField: 'data.training_date.seconds',
        text: 'Ngày huấn luyện',
        formatter: formatDate
      }, {
        dataField: 'data.training_expiry_date.seconds',
        text: 'Thời hạn',
        formatter: formatExpiredDate
      }, {
        dataField: 'data.no',
        text: 'QĐ kết quả',
      }, {
        dataField: '',
        text: '',
        formatter: (cell, row) => <CellAction
          modal={{
            save: ModalSaveDocumentHHNH
          }}
          coll_name={COLL_HANG_HOA_NGUY_HIEM}
          title={'Hàng hoá nguy hiểm'}
          row={row}
        />
      }
    ]]
  },
  {
    path: '/an-ninh-hang-khong',
    name: 'An ninh hàng không',
    coll_name: COLL_AN_NINH_HANG_KHONG,
    element: Document,
    modal: {
      save: ModalSaveDocumentANHK
    },
    columns: [...columnsEmp, ...[
      {
        dataField: 'data.category',
        text: 'Chương trình',
        filter: selectFilter({
          options: {
            'ANHK-KT-CT16': 'ANHK-KT-CT16',
            'ANHK-KT-CT17': 'ANHK-KT-CT17',
            'ANHK-KT-CT18': 'ANHK-KT-CT18',
            'ANHK-KT-CT28': 'ANHK-KT-CT28',
            'ANHK-ĐK-CT29': 'ANHK-ĐK-CT29',
            'ANHK-ĐK-CT31': 'ANHK-ĐK-CT31',
            'ANHK-ĐK-CT37': 'ANHK-ĐK-CT37',
          },
          placeholder: "Tất cả"
        })
      }, {
        dataField: 'data.training_place',
        text: 'Nơi đào tạo',
      }, {
        dataField: 'data.training_date.seconds',
        text: 'Ngày huấn luyện',
        formatter: formatDate
      }, {
        dataField: 'data.training_expiry_date.seconds',
        text: 'Thời hạn',
        formatter: formatExpiredDate
      }, {
        dataField: 'data.no',
        text: 'QĐ kết quả',
      }, {
        dataField: '',
        text: '',
        formatter: (cell, row) => <CellAction
          modal={{
            save: ModalSaveDocumentANHK
          }}
          coll_name={COLL_AN_NINH_HANG_KHONG}
          title={'An ninh hàng không'}
          row={row}
        />
      }
    ]]
  },
  {
    path: '/lai-xe-van-hanh-trang-thiet-bi',
    name: 'Lái xe vận hành trang thiết bị',
    coll_name: COLL_LAI_XE_VAN_HANH_TRANG_THIET_BI,
    element: Document,
    modal: {
      save: ModalSaveDocumentLXVHTTB
    },
    columns: [...columnsEmp, ...[
      {
        dataField: 'data.category',
        text: 'Tên thiết bị',
      }, {
        dataField: 'data.training_date_first.seconds',
        text: 'Ngày HLBĐ',
        formatter: formatDate
      }, {
        dataField: 'data.training_place',
        text: 'Nơi đào tạo',
      }, {
        dataField: 'data.training_date.seconds',
        text: 'Ngày HLĐK',
        formatter: formatDate
      }, {
        dataField: 'data.training_expiry_date.seconds',
        text: 'Thời hạn',
        formatter: formatExpiredDate
      }, {
        dataField: '',
        text: '',
        formatter: (cell, row) => <CellAction
          modal={{
            save: ModalSaveDocumentLXVHTTB
          }}
          coll_name={COLL_LAI_XE_VAN_HANH_TRANG_THIET_BI}
          title={'Lái xe vận hành trang thiết bị'}
          row={row}
        />
      }
    ]]
  },
  {
    path: '/giay-phep-nang-dinh',
    name: 'Giấy phép năng định',
    coll_name: COLL_GIAY_PHEP_NANG_DINH,
    element: Document,
    modal: {
      save: ModalSaveDocumentGPND
    },
    columns: [...columnsEmp, ...[
      {
        dataField: 'data.category',
        text: 'Tên năng định',
      }, {
        dataField: 'data.no_license',
        text: 'Số GPHK',
      }, {
        dataField: 'data.training_issue_date.seconds',
        text: 'Ngày cấp',
        formatter: formatDate
      }, {
        dataField: 'data.training_expiry_date.seconds',
        text: 'Thời hạn',
        formatter: formatExpiredDate
      }, {
        dataField: '',
        text: '',
        formatter: (cell, row) => <CellAction
          modal={{
            save: ModalSaveDocumentGPND
          }}
          coll_name={COLL_GIAY_PHEP_NANG_DINH}
          title={'Giấy phép năng định'}
          row={row}
        />
      }
    ]]
  },
  {
    path: '/trang-thiet-bi-nha-ga',
    name: 'Trang thiết bị nhà ga',
    coll_name: COLL_TRANG_THIET_BI_NHA_GA,
    element: Document,
    modal: {
      save: ModalSaveDocumentTTBNG
    },
    columns: [...columnsEmp, ...[
      {
        dataField: 'data.category',
        text: 'Tên thiết bị',
      }, {
        dataField: 'data.training_date_first.seconds',
        text: 'Ngày HLBĐ',
        formatter: formatDate
      }, {
        dataField: 'data.training_place',
        text: 'Nơi đào tạo',
      }, {
        dataField: 'data.training_date.seconds',
        text: 'Ngày HLĐK',
        formatter: formatDate
      }, {
        dataField: 'data.training_expiry_date.seconds',
        text: 'Thời hạn',
        formatter: formatExpiredDate
      }, {
        dataField: '',
        text: '',
        formatter: (cell, row) => <CellAction
          modal={{
            save: ModalSaveDocumentTTBNG
          }}
          coll_name={COLL_TRANG_THIET_BI_NHA_GA}
          title={'Trang thiết bị nhà ga'}
          row={row}
        />
      }
    ]]
  },
  {
    path: '/an-toan-san-do',
    name: 'An toàn sân đỗ',
    coll_name: COLL_AN_TOAN_SAN_DO,
    element: Document,
    modal: {
      save: ModalSaveDocument,
    },
    columns: [...columnsEmp, ...[
      {
        dataField: 'data.training_place',
        text: 'Nơi đào tạo',
      }, {
        dataField: 'data.training_date.seconds',
        text: 'Ngày huấn luyện',
        formatter: formatDate
      }, {
        dataField: 'data.training_expiry_date.seconds',
        text: 'Thời hạn',
        formatter: formatExpiredDate,
      }, {
        dataField: 'data.no',
        text: 'QĐ kết quả',
      }, {
        dataField: '',
        text: '',
        formatter: (cell, row) => <CellAction
          modal={{
            save: ModalSaveDocument,
          }}
          coll_name={COLL_AN_TOAN_SAN_DO}
          title={'An toàn sân đỗ'}
          row={row}
        />
      }
    ]]
  },
  {
    path: '/an-toan-ve-sinh-lao-dong',
    name: 'An toàn vệ sinh lao động',
    coll_name: COLL_AN_TOAN_VE_SINH_LAO_DONG,
    element: Document,
    modal: {
      save: ModalSaveDocument,
    },
    columns: [...columnsEmp, ...[
      {
        dataField: 'data.training_place',
        text: 'Nơi đào tạo',
      }, {
        dataField: 'data.training_date.seconds',
        text: 'Ngày huấn luyện',
        formatter: formatDate
      }, {
        dataField: 'data.training_expiry_date.seconds',
        text: 'Thời hạn',
        formatter: formatExpiredDate,
      }, {
        dataField: 'data.no',
        text: 'QĐ kết quả',
      }, {
        dataField: '',
        text: '',
        formatter: (cell, row) => <CellAction
          modal={{
            save: ModalSaveDocument,
          }}
          coll_name={COLL_AN_TOAN_VE_SINH_LAO_DONG}
          title={'An toàn vệ sinh lao động'}
          row={row}
        />
      }
    ]]
  },
  {
    path: '/boi-duong-ky-nang-mem',
    name: 'Bồi dưỡng kỹ năng mềm',
    coll_name: COLL_BOI_DUONG_KY_NANG_MEM,
    element: Document,
    modal: {
      save: ModalSaveDocument,
    },
    columns: [...columnsEmp, ...[
      {
        dataField: 'data.training_place',
        text: 'Nơi đào tạo',
      }, {
        dataField: 'data.training_date.seconds',
        text: 'Ngày huấn luyện',
        formatter: formatDate
      }, {
        dataField: 'data.training_expiry_date.seconds',
        text: 'Thời hạn',
        formatter: formatExpiredDate,
      }, {
        dataField: 'data.no',
        text: 'QĐ kết quả',
      }, {
        dataField: '',
        text: '',
        formatter: (cell, row) => <CellAction
          modal={{
            save: ModalSaveDocument,
          }}
          coll_name={COLL_BOI_DUONG_KY_NANG_MEM}
          title={'Bồi dưỡng kỹ năng mềm'}
          row={row}
        />
      }
    ]]
  },
  {
    path: '/quan-ly-an-ninh-hang-khong',
    name: 'Nghiệp vụ quản lý ANHK',
    coll_name: COLL_QL_AN_NINH_HANG_KHONG,
    element: Document,
    modal: {
      save: ModalSaveDocument,
    },
    columns: [...columnsEmp, ...[
      {
        dataField: 'data.training_place',
        text: 'Nơi đào tạo',
      }, {
        dataField: 'data.training_date.seconds',
        text: 'Ngày huấn luyện',
        formatter: formatDate
      }, {
        dataField: 'data.training_expiry_date.seconds',
        text: 'Thời hạn',
        formatter: formatExpiredDate,
      }, {
        dataField: 'data.no',
        text: 'QĐ kết quả',
      }, {
        dataField: '',
        text: '',
        formatter: (cell, row) => <CellAction
          modal={{
            save: ModalSaveDocument,
          }}
          coll_name={COLL_QL_AN_NINH_HANG_KHONG}
          title={'Nghiệp vụ quản lý ANHK'}
          row={row}
        />
      }
    ]]
  },
  {
    path: '/vu-khi-cong-cu-ho-tro',
    name: 'Vũ khí công cụ hỗ trợ',
    coll_name: COLL_VU_KHI_CONG_CU_HO_TRO,
    element: Document,
    modal: {
      save: ModalSaveDocument,
    },
    columns: [...columnsEmp, ...[
      {
        dataField: 'data.training_place',
        text: 'Nơi đào tạo',
      }, {
        dataField: 'data.training_date.seconds',
        text: 'Ngày huấn luyện',
        formatter: formatDate
      }, {
        dataField: 'data.training_expiry_date.seconds',
        text: 'Thời hạn',
        formatter: formatExpiredDate,
      }, {
        dataField: 'data.no',
        text: 'QĐ kết quả',
      }, {
        dataField: '',
        text: '',
        formatter: (cell, row) => <CellAction
          modal={{
            save: ModalSaveDocument,
          }}
          coll_name={COLL_VU_KHI_CONG_CU_HO_TRO}
          title={'Vũ khí công cụ hỗ trợ'}
          row={row}
        />
      }
    ]]
  },
  {
    path: '/hang-khong-dan-dung',
    name: 'Kiến thức HKDD',
    coll_name: COLL_HANG_KHONG_DAN_DUNG,
    element: Document,
    modal: {
      save: ModalSaveDocument,
    },
    columns: [...columnsEmp, ...[
      {
        dataField: 'data.training_place',
        text: 'Nơi đào tạo',
      }, {
        dataField: 'data.training_date.seconds',
        text: 'Ngày huấn luyện',
        formatter: formatDate
      }, {
        dataField: 'data.training_expiry_date.seconds',
        text: 'Thời hạn',
        formatter: formatExpiredDate,
      }, {
        dataField: 'data.no',
        text: 'QĐ kết quả',
      }, {
        dataField: '',
        text: '',
        formatter: (cell, row) => <CellAction
          modal={{
            save: ModalSaveDocument,
          }}
          coll_name={COLL_HANG_KHONG_DAN_DUNG}
          title={'Kiến thức HKDD'}
          row={row}
        />
      }
    ]]
  },
  {
    path: '/an-toan-buc-xa',
    name: 'An toàn bức xạ',
    coll_name: COLL_AN_TOAN_BUC_XA,
    element: Document,
    modal: {
      save: ModalSaveDocument,
    },
    columns: [...columnsEmp, ...[
      {
        dataField: 'data.training_place',
        text: 'Nơi đào tạo',
      }, {
        dataField: 'data.training_date.seconds',
        text: 'Ngày huấn luyện',
        formatter: formatDate
      }, {
        dataField: 'data.training_expiry_date.seconds',
        text: 'Thời hạn',
        formatter: formatExpiredDate,
      }, {
        dataField: '',
        text: '',
        formatter: (cell, row) => <CellAction
          modal={{
            save: ModalSaveDocument,
          }}
          coll_name={COLL_AN_TOAN_BUC_XA}
          title={'An toàn bức xạ'}
          row={row}
        />
      }
    ]]
  },
  {
    path: '/he-thong-quan-ly-an-toan',
    name: 'Hệ thống quản lý an toàn',
    coll_name: COLL_QUAN_LY_AN_TOAN,
    element: Document,
    modal: {
      save: ModalSaveDocument,
    },
    columns: [...columnsEmp, ...[
      {
        dataField: 'data.training_place',
        text: 'Nơi đào tạo',
      }, {
        dataField: 'data.training_date.seconds',
        text: 'Ngày huấn luyện',
        formatter: formatDate
      }, {
        dataField: 'data.training_expiry_date.seconds',
        text: 'Thời hạn',
        formatter: formatExpiredDate,
      }, {
        dataField: 'data.no',
        text: 'QĐ kết quả',
      }, {
        dataField: '',
        text: '',
        formatter: (cell, row) => <CellAction
          modal={{
            save: ModalSaveDocument,
          }}
          coll_name={COLL_QUAN_LY_AN_TOAN}
          title={'Hệ thống quản lý an toàn'}
          row={row}
        />
      }
    ]]
  },
  {
    path: '/dao-tao-khac',
    name: 'Đào tạo khác',
    coll_name: COLL_DAO_TAO_KHAC,
    element: Document,
    modal: {
      save: ModalSaveDocument,
    },
    columns: [...columnsEmp, ...[
      {
        dataField: 'data.training_place',
        text: 'Nơi đào tạo',
      }, {
        dataField: 'data.training_date.seconds',
        text: 'Ngày huấn luyện',
        formatter: formatDate
      }, {
        dataField: 'data.training_expiry_date.seconds',
        text: 'Thời hạn',
        formatter: formatExpiredDate,
      }, {
        dataField: 'data.no',
        text: 'QĐ kết quả',
      }, {
        dataField: '',
        text: '',
        formatter: (cell, row) => <CellAction
          modal={{
            save: ModalSaveDocument,
          }}
          coll_name={COLL_DAO_TAO_KHAC}
          title={'Đào tạo khác'}
          row={row}
        />
      }
    ]]
  },
]

export default routes
