export const INCREMENT = "INCREMENT";
export const DECREMENT = "DECREMENT";
export const RELOAD_TABLE = "RELOAD_TABLE";


export const COLL_NHAN_VIEN = "nhan-vien";
export const COLL_HANG_HOA_NGUY_HIEM = "hang-hoa-nguy-hiem";
export const COLL_AN_NINH_HANG_KHONG = "an-ninh-hang-khong";
export const COLL_AN_TOAN_SAN_DO = "an-toan-san-do";
export const COLL_LAI_XE_VAN_HANH_TRANG_THIET_BI = "lai-xe-van-hanh-trang-thiet-bi";
export const COLL_GIAY_PHEP_NANG_DINH = "giay-phep-nang-dinh";
export const COLL_TRANG_THIET_BI_NHA_GA = "trang-thiet-bi-nha-ga";
export const COLL_AN_TOAN_VE_SINH_LAO_DONG = "an-toan-ve-sinh-lao-dong";
export const COLL_BOI_DUONG_KY_NANG_MEM = "boi-duong-ky-nang-mem";
export const COLL_QL_AN_NINH_HANG_KHONG = "quan-ly-an-ninh-hang-khong";
export const COLL_VU_KHI_CONG_CU_HO_TRO = "vu-khi-cong-cu-ho-tro";
export const COLL_HANG_KHONG_DAN_DUNG = "hang-khong-dan-dung";
export const COLL_AN_TOAN_BUC_XA = "an-toan-buc-xa";
export const COLL_QUAN_LY_AN_TOAN = "he-thong-quan-ly-an-toan";
export const COLL_DAO_TAO_KHAC = "dao-tao-khac";

export const SELECT_EMP_DEFAULT_OPTION = [{
  "labelKey": "",
  "value": ""
}]
