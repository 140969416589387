import { DECREMENT, INCREMENT, RELOAD_TABLE } from '../const';

export const actIncrement = () => {
  return {
    type: INCREMENT
  }
}

export const actDecrement = () => {
  return {
    type: DECREMENT
  }
}

export const reloadTable = () => {
  return {
    type: RELOAD_TABLE
  }
}
