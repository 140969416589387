import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import DefaultLayout from './layout/DefaultLayout';
import Login from './pages/login/Login';
import Page404 from './pages/page404/Page404';
import Page500 from './pages/page500/Page500';

const loading = (
  <div className="spinner-border" role="status">
    <span className="sr-only">Loading...</span>
  </div>
)

function App() {
  return (
    <Suspense fallback={loading}>
      <Routes>
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/404" element={<Page404 />} />
        <Route exact path="/500" element={<Page500 />} />
        <Route path="*" element={<DefaultLayout />} />
      </Routes>
    </Suspense>
  );
}

export default App;
