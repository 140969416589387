import { addMonths, format, getMonth, getUnixTime, getYear } from 'date-fns';
import range from 'lodash/range';
import React from 'react';

export const customTotal = (from, to, size) => (
  <span className="react-bootstrap-table-pagination-total">
      {' '} { size } kết quả
    </span>
);

export const paginationOption = {
  sizePerPage: 10,
  paginationSize: 3,
  pageStartIndex: 1,
  showTotal: true,
  paginationTotalRenderer: customTotal
}

export const formatDate = (cell) => {
  return <>
    {format(new Date(cell * 1000), "dd/MM/yyyy")}
  </>
}

export const formatExpiredDate = (cell) => {
  if (isExpired(cell)) {
    return <>
      <span className="badge badge-warning">
        {format(new Date(cell * 1000), "dd/MM/yyyy")}
      </span>
    </>
  }
  return <>
    <span className="badge badge-success">
      {format(new Date(cell * 1000), "dd/MM/yyyy")}
    </span>
  </>
}

const years = range(2012, getYear(new Date()) + 10, 1);
const months = [
  "Tháng 1",
  "Tháng 2",
  "Tháng 3",
  "Tháng 4",
  "Tháng 5",
  "Tháng 6",
  "Tháng 7",
  "Tháng 8",
  "Tháng 9",
  "Tháng 10",
  "Tháng 11",
  "Tháng 12",
];

export const renderCustomHeader = ({
  date,
  changeYear,
  changeMonth,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}) => (
  <div
    style={{
      margin: 10,
      display: "flex",
      justifyContent: "center",
    }}
  >
    <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
      {"<"}
    </button>
    <select
      value={getYear(date)}
      onChange={({ target: { value } }) => changeYear(value)}
    >
      {years.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>

    <select
      value={months[getMonth(date)]}
      onChange={({ target: { value } }) =>
        changeMonth(months.indexOf(value))
      }
    >
      {months.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>

    <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
      {">"}
    </button>
  </div>
)

export const emptyData = () => {
  return <div className="text-center">Không có kết quả nào</div>
}

export const rowClasses = (row, rowIndex) => {
  if (isExpired(row.data.training_expiry_date.seconds)) {
    return 'table-warning';
  } else {
    return 'table-success';
  }
};

export const isExpired = (seconds) => {
  const expire_date = addMonths(new Date(), 6);
  return seconds <= getUnixTime(expire_date);
}
