import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch } from 'react-redux';
import { reloadTable } from '../../actions';
import DatabaseService from '../../services/DatabaseService';
import { renderCustomHeader } from '../../utils/TableUtil';
import { secondToDate } from '../../utils/Util';
import FormSelectEmp from '../commons/FormSelectEmp';
import ModalSaveHeader from '../commons/ModalSaveHeader';


const ModalSaveDocumentTTBNG = (props) => {
  const [saving, setSaving] = useState(false);
  const [validated, setValidated] = useState(false);
  const [trainingExpiryDate, setTrainingExpiryDate] = useState()
  const [trainingDate, setTrainingDate] = useState()
  const [trainingDateFirst, setTrainingDateFirst] = useState()
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.edit_mode) {
      setTrainingDateFirst(secondToDate(props.row.data.training_date_first.seconds))
      setTrainingDate(secondToDate(props.row.data.training_date.seconds))
      setTrainingExpiryDate(secondToDate(props.row.data.training_expiry_date.seconds))
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!props.edit_mode && !e.currentTarget.elements.id.value) {
      alert("Vui lòng chọn nhân viên!!!")
    } else if (!trainingDateFirst || !trainingDate || !trainingExpiryDate) {
      alert("Vui lòng chọn ngày huấn luyện / thời hạn!!!")
    } else {
      setSaving(true)
      let data = {
        category: e.currentTarget.elements.category.value,
        training_date_first: trainingDateFirst,
        training_date: trainingDate,
        training_place: e.currentTarget.elements.training_place.value,
        training_expiry_date: trainingExpiryDate,
      }
      if (props.edit_mode) {
        await DatabaseService.updateDoc(props.coll_name, props.row.id, data)
      } else {
        data.emp_id = e.currentTarget.elements.id.value
        await DatabaseService.addDoc(props.coll_name, data)
      }
      console.log('save ok');
      props.close();
      dispatch(reloadTable());
    }
  };

  return (
    <Modal show={true} backdrop="static" keyboard={false}>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <ModalSaveHeader {...props}/>
        <Modal.Body>
          <FormSelectEmp {...props}/>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="category">
              <Form.Label>Tên thiết bị</Form.Label>
              <Form.Control as="select" name="category" defaultValue={props.edit_mode ? props.row.data.category : ''} required>
                <option value="">Vui lòng chọn</option>
                <option value="Nguồn">Nguồn</option>
                <option value="Báo cháy">Báo cháy</option>
                <option value="Chữa cháy">Chữa cháy</option>
                <option value="Điều hòa">Điều hòa</option>
                <option value="Thông tin chuyến bay">Thông tin chuyến bay</option>
                <option value="Băng chuyền">Băng chuyền</option>
              </Form.Control>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="training_place">
              <Form.Label>Nơi cấp</Form.Label>
              <Form.Control name="training_place" defaultValue={props.edit_mode ? props.row.data.training_place : ''} required/>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="training_date_first">
              <Form.Label>Ngày HLBĐ</Form.Label>
              <DatePicker
                renderCustomHeader={renderCustomHeader}
                dateFormat="dd/MM/yyyy"
                selected={trainingDateFirst}
                onChange={(date) => setTrainingDateFirst(date)} />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} md="6" controlId="training_date">
              <Form.Label>Ngày HLĐK</Form.Label>
              <DatePicker
                renderCustomHeader={renderCustomHeader}
                dateFormat="dd/MM/yyyy"
                selected={trainingDate}
                onChange={(date) => setTrainingDate(date)} />
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="training_expiry_date">
              <Form.Label>Thời hạn</Form.Label>
              <DatePicker
                renderCustomHeader={renderCustomHeader}
                dateFormat="dd/MM/yyyy"
                selected={trainingExpiryDate} 
                onChange={(date) => setTrainingExpiryDate(date)} />
            </Form.Group>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={props.close}>Thoát</Button>
          {!saving
            ?
            <Button variant="primary" type="submit">Lưu</Button>
            :
            <Button variant="primary" disabled>
              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              {' '}Đang lưu...
            </Button>
          }
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default React.memo(ModalSaveDocumentTTBNG)

