import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';
import { useDispatch } from 'react-redux';
import { reloadTable } from '../../actions';
import DatabaseService from '../../services/DatabaseService';
import ModalService from '../../services/ModalService';

const CellAction = ({modal, coll_name, title, row}) => {
  const [deleting, setDeleting] = useState(false);
  const dispatch = useDispatch();
  const edit_mode = true;
  const deleteDoc = (doc_id) => {
    setDeleting(true)
    DatabaseService.deleteDoc(coll_name, doc_id).then(r => {
      console.log("delete success")
      dispatch(reloadTable())
      setDeleting(false)
    })
  }
  return (
    <>
      <button type="button" className="btn btn-warning btn-sm mr-2" onClick={() => ModalService.open(modal.save, {edit_mode, coll_name, title, row})}><i className="fas fa-edit"></i></button>
      <button type="button" className="btn btn-danger btn-sm" onClick={() => confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <>
              <Modal show={true}>
                <Modal.Header>
                  <Modal.Title>Delete Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body><div className="alert alert-danger">Chắc chắn xoá?</div></Modal.Body>
                <Modal.Footer>
                  <Button variant="light" onClick={onClose}>Thoát</Button>
                  {!deleting
                    ?
                    <Button variant="danger" onClick={() => {
                      deleteDoc(row.id)
                      onClose()
                    } }>
                      Xoá
                    </Button>
                    :
                    <Button variant="danger" disabled>
                      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      {' '}Đang xoá...
                    </Button>
                  }
                </Modal.Footer>
              </Modal>
            </>
          );
        }
      })
      }><i className="fas fa-ban"></i></button>
    </>
  )
}

export default React.memo(CellAction)
