import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase';

const AppSidebar = () => {
  const [user] = useAuthState(auth);
  const navigate = useNavigate();

  if (!user) {
    navigate('/login')
  }

  return (
    <nav className="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
      <div className="sb-sidenav-menu">
        <div className="nav">
          <div className="sb-sidenav-menu-heading">Core</div>
          <a className="nav-link" href="/dashboard">
            <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
            Trang chủ
          </a>
          <a className="nav-link" href="/ho-so-dao-tao">
            <div className="sb-nav-link-icon"><i className="fas fa-user"></i></div>
            Hồ sơ nhân viên
          </a>
          <div className="sb-sidenav-menu-heading">Training</div>
          <a className="nav-link" href="/hang-hoa-nguy-hiem">
            <div className="sb-nav-link-icon"><i className="fas fa-book"></i></div>
            Hàng hóa nguy hiểm
          </a>
          <a className="nav-link" href="/an-ninh-hang-khong">
            <div className="sb-nav-link-icon"><i className="fas fa-book"></i></div>
            An ninh hàng không
          </a>
          <a className="nav-link" href="/lai-xe-van-hanh-trang-thiet-bi">
            <div className="sb-nav-link-icon"><i className="fas fa-book"></i></div>
            Lái xe & Vận hành trang thiết bị
          </a>
          <a className="nav-link" href="/giay-phep-nang-dinh">
            <div className="sb-nav-link-icon"><i className="fas fa-book"></i></div>
            Giấy phép, năng định
          </a>
          <a className="nav-link" href="/trang-thiet-bi-nha-ga">
            <div className="sb-nav-link-icon"><i className="fas fa-book"></i></div>
            Trang thiết bị nhà ga
          </a>
          <a className="nav-link" href="/an-toan-san-do">
            <div className="sb-nav-link-icon"><i className="fas fa-book"></i></div>
            An toàn sân đỗ
          </a>
          <a className="nav-link" href="/an-toan-ve-sinh-lao-dong">
            <div className="sb-nav-link-icon"><i className="fas fa-book"></i></div>
            An toàn vệ sinh lao động
          </a>
          <a className="nav-link" href="/boi-duong-ky-nang-mem">
            <div className="sb-nav-link-icon"><i className="fas fa-book"></i></div>
            Bồi dưỡng kỹ năng mềm
          </a>
          <a className="nav-link" href="/quan-ly-an-ninh-hang-khong">
            <div className="sb-nav-link-icon"><i className="fas fa-book"></i></div>
            Nghiệp vụ quản lý ANHK
          </a>
          <a className="nav-link" href="/vu-khi-cong-cu-ho-tro">
            <div className="sb-nav-link-icon"><i className="fas fa-book"></i></div>
            Vũ khí công cụ hỗ trợ
          </a>
          <a className="nav-link" href="/hang-khong-dan-dung">
            <div className="sb-nav-link-icon"><i className="fas fa-book"></i></div>
            Kiến thức HKDD
          </a>
          <a className="nav-link" href="/an-toan-buc-xa">
            <div className="sb-nav-link-icon"><i className="fas fa-book"></i></div>
            An toàn bức xạ
          </a>
          <a className="nav-link" href="/he-thong-quan-ly-an-toan">
            <div className="sb-nav-link-icon"><i className="fas fa-book"></i></div>
            Hệ thống quản lý an toàn
          </a>
          <a className="nav-link" href="/dao-tao-khac">
            <div className="sb-nav-link-icon"><i className="fas fa-book"></i></div>
            Đào tạo khác
          </a>
        </div>
      </div>
      {user && <div className="sb-sidenav-footer"><div className="small">Logged in as: {user.email}</div></div>}
    </nav>
  )
}

export default React.memo(AppSidebar)
