import React from 'react';
import { AppContent } from '../components/AppContent';
import AppHeader from '../components/AppHeader';
import AppSidebar from '../components/AppSidebar';

const DefaultLayout = () => {
  return (
    <>
      <AppHeader/>
      <div id="layoutSidenav">
        <div id="layoutSidenav_nav">
          <AppSidebar/>
        </div>
        <div id="layoutSidenav_content">
          <div className="container-fluid">
            <AppContent/>
          </div>
        </div>
      </div>
    </>
  )
}

export default DefaultLayout
