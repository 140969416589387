import { fromUnixTime, parse } from 'date-fns';

export const delay = ms => new Promise(res => setTimeout(res, ms));

export const secondToDate = (seconds) => {
  return fromUnixTime(seconds)
}

export const stringToDate = (str) => {
  return parse(str, 'dd/MM/yyyy', new Date())
}
