import { RELOAD_TABLE } from '../const';

const initialState = {
  count: 0
};

const reloadTableReducer = (state = initialState, action) => {
  switch(action.type) {
    case RELOAD_TABLE:
      return {
        count: state.count + 1
      };
    default:
      return state;
  }
};

export default reloadTableReducer
