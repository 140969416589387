import React from 'react';
import { useSignOut } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import LocalStorageService from '../services/LocalStorageService';

const AppHeader = () => {
  const [signOut] = useSignOut(auth);
  const navigate = useNavigate()

  const handleLogout = async () => {
    const success = await signOut();
    if (success) {
      LocalStorageService.clearCache()
      alert('Đăng xuất thành công!');
      navigate('/login')
    }
  }

  const handleSetting = () => {
    navigate('/setting')
  }

  return (
    <nav className="sb-topnav navbar navbar-expand navbar-dark bg-dark">
      <a className="navbar-brand" href="/">{process.env.REACT_APP_TITLE}</a>
      <button className="btn btn-link btn-sm order-1 order-lg-0 border-secondary" id="sidebarToggle">
        <i className="fas fa-bars"></i></button>

      <form className="d-none d-md-inline-block form-inline ml-auto mr-0 mr-md-3 my-2 my-md-0">
        <div className="input-group">
          <input className="form-control"
                 type="text"
                 placeholder="Search for..."
                 aria-label="Search"
                 aria-describedby="basic-addon2"/>
          <div className="input-group-append">
            <button className="btn btn-primary" type="button"><i className="fas fa-search"></i></button>
          </div>
        </div>
      </form>

      <ul className="navbar-nav ml-auto ml-md-0">
        <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle"
             id="userDropdown"
             href="/"
             role="button"
             data-toggle="dropdown"
             aria-haspopup="true"
             aria-expanded="false"><i className="fas fa-user fa-fw"></i></a>
          <div className="dropdown-menu dropdown-menu-right" aria-labelledby="userDropdown">
            <button className="dropdown-item" type="button" onClick={handleSetting}><i className="fas fa-cog"></i> Cấu hình</button>
            <div className="dropdown-divider"></div>
            <button className="dropdown-item" type="button" onClick={handleLogout}><i className="fas fa-sign-out-alt"></i> Đăng xuất</button>
          </div>
        </li>
      </ul>
    </nav>
  )
}

export default React.memo(AppHeader)
